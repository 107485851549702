import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "/builder",
        name: "builder",
        component: () => import("@/views/Builder.vue"),
      },
      {
        path: "/crafted/pages/profile",
        name: "profile",
        component: () => import("@/components/page-layouts/Profile.vue"),
        children: [
          {
            path: "overview",
            name: "profile-overview",
            component: () =>
              import("@/views/crafted/pages/profile/Overview.vue"),
          },
          {
            path: "projects",
            name: "profile-projects",
            component: () =>
              import("@/views/crafted/pages/profile/Projects.vue"),
          },
          {
            path: "campaigns",
            name: "profile-campaigns",
            component: () =>
              import("@/views/crafted/pages/profile/Campaigns.vue"),
          },
          {
            path: "documents",
            name: "profile-documents",
            component: () =>
              import("@/views/crafted/pages/profile/Documents.vue"),
          },
          {
            path: "connections",
            name: "profile-connections",
            component: () =>
              import("@/views/crafted/pages/profile/Connections.vue"),
          },
          {
            path: "activity",
            name: "profile-activity",
            component: () =>
              import("@/views/crafted/pages/profile/Activity.vue"),
          },
        ],
      },
      {
        path: "/crafted/pages/wizards/horizontal",
        name: "horizontal-wizard",
        component: () => import("@/views/crafted/pages/wizards/Horizontal.vue"),
      },
      {
        path: "/crafted/pages/wizards/vertical",
        name: "vertical-wizard",
        component: () => import("@/views/crafted/pages/wizards/Vertical.vue"),
      },
      {
        path: "/crafted/account",
        name: "account",
        component: () => import("@/views/crafted/account/Account.vue"),
        children: [
          {
            path: "overview",
            name: "account-overview",
            component: () => import("@/views/crafted/account/Overview.vue"),
          },
          {
            path: "settings",
            name: "account-settings",
            component: () => import("@/views/crafted/account/Settings.vue"),
          },
        ],
      },
      {
        path: "/apps/customers/getting-started",
        name: "apps-customers-getting-started",
        component: () => import("@/views/apps/customers/GettingStarted.vue"),
      },
      {
        path: "/apps/customers/customers-listing",
        name: "apps-customers-listing",
        component: () => import("@/views/apps/customers/CustomersListing.vue"),
      },
      {
        path: "/apps/customers/customer-details",
        name: "apps-customers-details",
        component: () => import("@/views/apps/customers/CustomerDetails.vue"),
      },
      {
        path: "/subscriptions/getting-started",
        name: "subscriptions-getting-started",
        component: () =>
          import("@/views/apps/subscriptions/GettingStarted.vue"),
      },
      {
        path: "/subscriptions/subscription-list",
        name: "subscriptions-subscription-list",
        component: () =>
          import("@/views/apps/subscriptions/SubscriptionList.vue"),
      },
      {
        path: "/subscriptions/add-subscription",
        name: "subscriptions-add-subscription",
        component: () =>
          import("@/views/apps/subscriptions/AddSubscription.vue"),
      },
      {
        path: "/subscriptions/view-subscription",
        name: "subscriptions-view-subscription",
        component: () =>
          import("@/views/apps/subscriptions/ViewSubscription.vue"),
      },
      {
        path: "/apps/calendar",
        name: "apps-calendar",
        component: () => import("@/views/apps/Calendar.vue"),
      },
      {
        path: "/apps/chat/private-chat",
        name: "apps-private-chat",
        component: () => import("@/views/apps/chat/Chat.vue"),
      },
      {
        path: "/apps/chat/group-chat",
        name: "apps-group-chat",
        component: () => import("@/views/apps/chat/Chat.vue"),
      },
      {
        path: "/apps/chat/drawer-chat",
        name: "apps-drawer-chat",
        component: () => import("@/views/apps/chat/DrawerChat.vue"),
      },
      {
        path: "/crafted/modals/general/invite-friends",
        name: "modals-general-invite-friends",
        component: () =>
          import("@/views/crafted/modals/general/InviteFriends.vue"),
      },
      {
        path: "/crafted/modals/general/view-user",
        name: "modals-general-view-user",
        component: () => import("@/views/crafted/modals/general/ViewUsers.vue"),
      },
      {
        path: "/crafted/modals/general/upgrade-plan",
        name: "modals-general-upgrade-plan",
        component: () =>
          import("@/views/crafted/modals/general/UpgradePlan.vue"),
      },
      {
        path: "/crafted/modals/general/share-and-earn",
        name: "modals-general-share-and-earn",
        component: () =>
          import("@/views/crafted/modals/general/ShareAndEarn.vue"),
      },
      {
        path: "/crafted/modals/forms/new-target",
        name: "modals-forms-new-target",
        component: () => import("@/views/crafted/modals/forms/NewTarget.vue"),
      },
      {
        path: "/crafted/modals/forms/new-card",
        name: "modals-forms-new-card",
        component: () => import("@/views/crafted/modals/forms/NewCard.vue"),
      },
      {
        path: "/crafted/modals/forms/new-address",
        name: "modals-forms-new-address",
        component: () => import("@/views/crafted/modals/forms/NewAddress.vue"),
      },
      {
        path: "/crafted/modals/forms/create-api-key",
        name: "modals-forms-create-api-key",
        component: () =>
          import("@/views/crafted/modals/forms/CreateApiKey.vue"),
      },
      {
        path: "/crafted/modals/wizards/two-factor-auth",
        name: "modals-wizards-two-factor-auth",
        component: () =>
          import("@/views/crafted/modals/wizards/TwoFactorAuth.vue"),
      },
      {
        path: "/crafted/modals/wizards/create-app",
        name: "modals-wizards-create-app",
        component: () => import("@/views/crafted/modals/wizards/CreateApp.vue"),
      },
      {
        path: "/crafted/modals/wizards/create-account",
        name: "modals-wizards-create-account",
        component: () =>
          import("@/views/crafted/modals/wizards/CreateAccount.vue"),
      },
      {
        path: "/crafted/widgets/lists",
        name: "widgets-list",
        component: () => import("@/views/crafted/widgets/Lists.vue"),
      },
      {
        path: "/crafted/widgets/statistics",
        name: "widgets-statistics",
        component: () => import("@/views/crafted/widgets/Statistics.vue"),
      },
      {
        path: "/crafted/widgets/charts",
        name: "widgets-charts",
        component: () => import("@/views/crafted/widgets/Charts.vue"),
      },
      {
        path: "/crafted/widgets/mixed",
        name: "widgets-mixed",
        component: () => import("@/views/crafted/widgets/Mixed.vue"),
      },
      {
        path: "/crafted/widgets/tables",
        name: "widgets-tables",
        component: () => import("@/views/crafted/widgets/Tables.vue"),
      },
      {
        path: "/crafted/widgets/feeds",
        name: "widgets-feeds",
        component: () => import("@/views/crafted/widgets/Feeds.vue"),
      },
    ],
  },
  {
    path: "/adm",
    redirect: "/adm/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "dashboard",
        name: "dashboard-adm",
        component: () => import("@/views/Builder.vue"),
      },
      {
        path: "ist",
        name: "ist-adm",
        component: () => import("@/views/psy/ist/IstAdm.vue"),
      },
      {
        path: "minat-bakat",
        name: "minat-bakat-adm",
        component: () => import("@/views/psy/minat-bakat/MinatBakatAdm.vue"),
      },
      {
        path: "modalitas-belajar",
        name: "modalitas-belajar-adm",
        component: () => import("@/views/psy/modalitas-belajar/ModalitasBelajarAdm.vue"),
      },
      {
        path: "papi-kostick",
        name: "papi-kostick-adm",
        component: () => import("@/views/psy/papi-kostick/PapiKostickAdm.vue"),
      },
      {
        path: "cfit/a",
        name: "cfit-adm",
        component: () => import("@/views/psy/cfit/CfitAdm.vue"),
      },
      {
        path: "cfit/b",
        name: "cfitb-adm",
        component: () => import("@/views/psy/cfit/CfitBAdm.vue"),
      },
    ],
  },
  {
    path: "/usr",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/usr/dashboard",
        name: "dashboard-usr",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "ist",
        name: "ist-usr",
        component: () => import("@/views/psy/ist/IstUsr.vue"),
      },
      {
        path: "minat-bakat",
        name: "minat-bakat-usr",
        component: () => import("@/views/psy/minat-bakat/MinatBakatUsr.vue"),
      },
      {
        path: "modalitas-belajar",
        name: "modalitas-belajar-usr",
        component: () => import("@/views/psy/modalitas-belajar/ModalitasBelajarUsr.vue"),
      },
      {
        path: "disc",
        name: "disc-usr",
        component: () => import("@/views/psy/disc/DiscUsr.vue"),
      },
      {
        path: "riasec",
        name: "riasec-usr",
        component: () => import("@/views/psy/riasec/RiasecUsr.vue"),
      },
      {
        path: "kraepelin",
        name: "kraepelin-usr",
        component: () => import("@/views/psy/kraepelin/KraepelinUsr.vue"),
      },
      {
        path: "mmpi",
        name: "mmpi-usr",
        component: () => import("@/views/psy/mmpi/MmpiUsr.vue"),
      },
      {
        path: "papi-kostick",
        name: "papi-kostick-usr",
        component: () => import("@/views/psy/papi-kostick/PapiKostickUsr.vue"),
      },
      {
        path: "cfit/a",
        name: "cfit-usr",
        component: () => import("@/views/psy/cfit/CfitUsr.vue"),
      },
      {
        path: "cfit/b",
        name: "cfitb-usr",
        component: () => import("@/views/psy/cfit/CfitBUsr.vue"),
      },
    ],
  },
  {
    path: "/marker",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "import-peserta",
        name: "import-peserta",
        component: () => import("@/views/import/peserta/ImportPeserta.vue"),
      },
      {
        path: "disc",
        name: "disc-mrk",
        component: () => import("@/views/psy/disc/DiscMrk.vue"),
      },
      {
        path: "riasec",
        name: "riasec-mrk",
        component: () => import("@/views/psy/riasec/RiasecMrk.vue"),
      },
      {
        path: "kraepelin",
        name: "kraepelin-mrk",
        component: () => import("@/views/psy/kraepelin/KraepelinMrk.vue"),
      },
      {
        path: "mmpi",
        name: "mmpi-mrk",
        component: () => import("@/views/psy/mmpi/MmpiMrk.vue"),
      },
      {
        path: "minat-bakat",
        name: "minat-bakat-mrk",
        component: () => import("@/views/psy/minat-bakat/MinatBakatMrk.vue"),
      },
      {
        path: "modalitas-belajar",
        name: "modalitas-belajar-mrk",
        component: () => import("@/views/psy/modalitas-belajar/ModalitasBelajarMrk.vue"),
      },
      {
        path: "papi-kostick",
        name: "papi-kostick-mrk",
        component: () => import("@/views/psy/papi-kostick/PapiKostickMrk.vue"),
      },
      {
        path: "cfit/a",
        name: "cfit-mrk",
        component: () => import("@/views/psy/cfit/CfitMrk.vue"),
      },
      {
        path: "cfit/b",
        name: "cfitb-mrk",
        component: () => import("@/views/psy/cfit/CfitBMrk.vue"),
      },
    ],
  },
  {
    path: "/test",
    redirect: "/",
    component: () => import("@/layout/Layout2.vue"),
    children: [
      {
        path: "papi-kostick-2/:id/:token",
        name: "papi-kostick-usr-2",
        component: () => import("@/views/psy/papi-kostick2/PapiKostickUsr2.vue"),
        props: true,
      },
      {
        path: "ist-2/:id/:token",
        name: "ist-2",
        component: () => import("@/views/psy/ist2/IstUsr2.vue"),
        props: true,
      },
      {
        path: "cfita-2/:id/:token",
        name: "cfita-2",
        component: () => import("@/views/psy/cfit2/CfitUsr2.vue"),
        props: true,
      },
      {
        path: "cfitb-2/:id/:token",
        name: "cfitb-2",
        component: () => import("@/views/psy/cfit2/CfitBUsr2.vue"),
        props: true,
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(() => {
  /////////////////// PROYEK_TY
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
  /////////////////// PROYEK_TY
});

export default router;
